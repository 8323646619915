export const UPDATE_SHAPE_UNITS_COUNT = "UPDATE_SHAPE_UNITS_COUNT";
export const UPDATE_GRID = "UPDATE_GRID";
export const START_DROP_NEW_BLOCK = "START_DROP_NEW_BLOCK";
export const STOP_DROP_NEW_BLOCK = "STOP_DROP_NEW_BLOCK";
export const DELETE_ROW = "DELETE_ROW";

export const UPDATE_SCORE = "UPDATE_SCORE";
export const NEXT_LEVEL = "NEXT_LEVEL";
export const GAME_OVER = "GAME_OVER";
export const PAUSE_GAME = "PAUSE_GAME";
export const RESUME_GAME = "RESUME_GAME";
export const INCREMENT_SHAPES_DROPPED = "INCREMENT_SHAPES_DROPPED";
export const INCREMENT_CLEARED_LINES = "INCREMENT_CLEARED_LINES";
export const SHOW_MENU = "SHOW_MENU";
export const HIDE_MENU = "HIDE_MENU";
export const NEW_GAME = "NEW_GAME";
export const UPDATE_GAME_STATUS = "UPDATE_GAME_STATUS";
export const UPDATE_HIGH_SCORES = "UPDATE_HIGH_SCORES";
